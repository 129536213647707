import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'

export default class SubmenuConfig extends Model {
    endpoint = 'menu_config'

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('text_es', 'Texto en español'),
      new Field('text_en', 'Texto en inglés'),
      new Field('url', 'URL').applyMask(() => '<UrlComponent :model="model"/>'),
      new Field('url_en', 'URL en inglés').hide(),
    ]

    clone=() => SubmenuConfig;
}
