<template>
  <div>
    <table-component
      :model="model"
    >
      <!--            <template v-slot:actions>
        <b-button
          variant="outline-primary"
          class="btn-icon"
          @click="gotoMenuMultimedia"
        >
          Config. Imágenes en el menú
        </b-button>
      </template>-->
    </table-component>
  </div>
</template>

<script>
import TableComponent from '@/dc-it/components/TableComponent.vue'
import MenuConfig from '@/dc-it/models/MenuConfig'
/* import { BButton } from 'bootstrap-vue' */

export default {
  name: 'MenuConfigView',
  components: { TableComponent },
  data() {
    return {
      model: MenuConfig,
    }
  },
}
</script>

<style scoped>

</style>
