import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'
import MultipleRelation from '@/dc-it/types/MutipleRelation'
import SubmenuConfig from '@/dc-it/models/SubmenuConfig'

export default class MenuConfig extends Model {
    endpoint = 'menu_config'

    related = ['menu_configs']

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('text_es', 'Texto en español'),
      new Field('text_en', 'Texto en inglés'),
      new Field('url', 'URL').applyMask(() => '<UrlComponent :model="model"/>'),
      new Field('url_en', 'URL en inglés').hide(),
      new MultipleRelation('menu_configs', 'submenus', SubmenuConfig),
    ]

    clone=() => MenuConfig;
}
